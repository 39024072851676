import $ from 'jquery';
import 'datatables.net';
import './bootstrap';
import 'select2';
// import 'daterangepicker';
// import Pickr from '@simonwep/pickr';
import Cookies from './js.cookie';

$.extend( true, $.fn.dataTable.defaults, {
    language: {
        url : '/datatables_localization/' + window.user_lang + '.json'
    },
    // stateSave: true,
    pagination: true,
    // pageLength: 10,
    dom: '<"row"<"col text-left"f><"col text-right"l>>rt<"d-flex justify-content-center py-2 text-muted"i><"d-flex justify-content-center py-2"p><"clear">'
    // lengthChange: true,
} );

function getTranslateString(string) {

    if (typeof translations === 'undefined') {
        return string;
    }

    if (typeof translations[string] !== 'undefined') {
        return translations[string];
    }

    return string;
}

$(document).ready(function () {

    /* Datatables */
    // Init
    // var myDatatable = $(".datatable").DataTable({ pageLength : 2, lengthMenu: [[5, 10, 20, -1], [5, 10, 20, "Todos"]]});
    var myDatatable = $(".datatable").DataTable();
    // Toggle
    $('a.toggle-vis').on( 'click', function (e) {
        e.preventDefault();
        var column = myDatatable.column( $(this).attr('data-column') );
        column.visible( ! column.visible() );
    } );
    // 
    $('[data-toggle="tooltip"]').tooltip('enable')

    $('body').on('click', '#sidebar-bottom button', function (event) {
        $('#sidebar-wrapper').toggleClass('col-2').toggleClass('col-1');
        $('#content-wrapper').toggleClass('offset-2').toggleClass('offset-1');

        if (  $('#sidebar-wrapper').hasClass('col-2')) {
            // $('#sidebar-wrapper #sidebar-menu [data-toggle="tooltip"]').tooltip('disable')
            Cookies.set('sidebar-status', 'opened', {expires: 7, path: '/'});
        } else {
            // $('#sidebar-wrapper #sidebar-menu [data-toggle="tooltip"]').tooltip('enable')
            Cookies.set('sidebar-status', 'closed', {expires: 7, path: '/'});
        }
    });

    $('body').on('click', '.close-modal', function (event) {
        $(event.currentTarget).closest('.modal').modal('hide');
    });

    $(document).on('hidden.bs.modal', function (event) {
        if ($('.modal:visible').length) {
            $('body').addClass('modal-open');
        }
    });

    // 
    if ($('.dtc-select').length > 0) {
        // $('.dtc-select').select2();

        $(".dtc-select").select2({
            templateResult: formatState,
            templateSelection: formatState
        });

        function formatState(opt) {
            if (!opt.id) {
                return opt.text;
            }

            let optimage = $(opt.element).attr('data-logo');

            if (!optimage) {
                return opt.text;
            } else {
                let $opt = $(
                    '<img src="' + optimage + '" width="60px" /> ' + opt.text + '</>'
                );
                return $opt;
            }
        };
    }

});
